import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import isSSR from '@utils/isSSR'
import { breakpointSmediumAndBelow } from '@components/StyledComponents/theme/helpers/breakpoints'

const PADDING = 4
const BILLBOARD_HEIGHT = 108
const BILLBOARD_WIDTH = 728
const BILLBOARD_LARGE_HEIGHT = 258
const MREC_HEIGHT = 264
const MREC_WIDTH = 300

const AdContainer = styled.div`
  display: flex;
  justify-content: ${({ align }) => align || 'center'};
  align-items: center;
  width: 100%;
  height: 100%;

  ${breakpointSmediumAndBelow(css`
    justify-content: center;
  `)}
`

const AdWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  ${({ type }) =>
    type === 'billboard' &&
    css`
      margin: 8px 0px 8px 0px;
      min-height: ${BILLBOARD_HEIGHT + PADDING * 2}px;
    `}

  ${({ type }) =>
    type === 'billboard-sticky' &&
    css`
      width: ${BILLBOARD_WIDTH}px;
      min-height: ${BILLBOARD_HEIGHT + PADDING * 2}px;
    `}

  ${({ type }) =>
    type === 'billboard-large' &&
    css`
      min-height: ${BILLBOARD_LARGE_HEIGHT + PADDING * 2}px;
    `}

  ${({ type }) =>
    type === 'mrec' &&
    css`
      min-width: ${MREC_WIDTH}px;
      min-height: ${MREC_HEIGHT + PADDING * 2}px;
    `}

  &::before {
    position: absolute;
    top: 0;
    content: 'Advertisement';
    color: gray;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins',
    text-align: center;
  }
`

const AdNoBid = ({ code, className, targeting, type = 'billboard', align }) => {
  const [isClientSide, setIsClientSide] = useState(false)
  const [myId, setMyId] = useState('')

  useEffect(() => {
    setIsClientSide(true)
    const id = myId || `${code}_${Math.ceil(Math.random() * 9999)}`
    setMyId(id)

    if (isClientSide && typeof window !== 'undefined' && typeof window.wrapup !== 'undefined') {
      window.wrapup.cmd.push(() => {
        window.wrapup.defineSlot(id, code, targeting)
      })

      return () => {
        window.wrapup.cmd.push(() => {
          window.wrapup.destroySlot(id)
        })
      }
    }
    return () => {}
  }, [isClientSide])

  if (type === 'pixel') {
    return (
      <div id={`ctr_${myId}`} className={className} data-testid={`ctr_${code}`}>
        <div id={myId} />
      </div>
    )
  }

  return (
    <AdContainer align={align}>
      <AdWrapper type={type}>
        {isSSR() ? null : (
          <div
            id={`ctr_${myId}`}
            className={className}
            data-testid={`ctr_${code}`}
            role="complementary"
          >
            <div id={myId} />
          </div>
        )}
      </AdWrapper>
    </AdContainer>
  )
}

AdNoBid.propTypes = {
  code: PropTypes.string,
  /**
   * We pass the className here so that we can apply styles using the styled-components library
   */
  className: PropTypes.string,
  /**
   * Dynamic key-value pairs for ad targeting, i.e. { targeting: { position: "atf" }}
   */
  // eslint-disable-next-line react/forbid-prop-types
  targeting: PropTypes.object,
  type: PropTypes.string,
  align: PropTypes.string
}

export default AdNoBid
