import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import RatingTypes from '@types/Rating.types'
import CardNumRating, { CardNumRatingHeader } from '@StyledComponents/CardNumRating'

export const StyledRatingValues = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpointSmallPlusAndBelow(
    css`
      flex-direction: row;
    `
  )}
  ${CardNumRatingHeader} {
    font-size: ${({ theme }) => theme.fontSize.default};
  }
`

export const RatingContainer = styled.div`
  margin-bottom: 24px;

  ${breakpointSmallPlusAndBelow(
    css`
      margin: 0 29px 23px 0;
    `
  )}
`

export function RatingValues({ rating }) {
  const theme = useContext(ThemeContext)

  const { helpfulRating, clarityRating, difficultyRating } = rating
  const overallRating = (helpfulRating + clarityRating) / 2

  const overallNumRatingConfig = {
    topText: 'Quality',
    numRating: overallRating
  }

  const difficultyNumRatingConfig = {
    topText: 'Difficulty',
    numRating: difficultyRating,
    staticColor: theme.color.gray5
  }

  return (
    <StyledRatingValues>
      <RatingContainer>
        <CardNumRating config={overallNumRatingConfig} />
      </RatingContainer>
      <RatingContainer>
        <CardNumRating config={difficultyNumRatingConfig} />
      </RatingContainer>
    </StyledRatingValues>
  )
}

RatingValues.propTypes = {
  rating: RatingTypes
}

export default createFragmentContainer(RatingValues, {
  rating: graphql`
    fragment RatingValues_rating on Rating {
      helpfulRating
      clarityRating
      difficultyRating
    }
  `
})
