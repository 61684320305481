/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Redirect, withRouter, useHistory, matchPath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { graphql } from 'react-relay'
import LOCK_STATUS from '@enums/LOCK_STATUS'
import ROUTE from '@enums/ROUTE'
import * as Breakpoints from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PageWrapper from '@components/PageWrapper'
import TeacherInfo, { StyledTeacher } from '@components/TeacherInfo/TeacherInfo'
import TeacherRatingTabs, { StyledTabs } from '@components/TeacherRatingTabs'
import SimilarProfessors from '@components/SimilarProfessors'
import AdRail from '@components/Ads/AdRail'
import RedirectWithStatus from '@components/RedirectWithStatus'
import toast, { TOAST_EVENTS } from '@utils/toast'
import SoftlockedTeacherToast from '@components/Toast/SoftlockedTeacherToast'
import HardlockedTeacherToast from '@components/Toast/HardlockedTeacherToast'
import FlagRatingSuccessToast from '@components/Toast/FlagRatingSuccessToast'
import useUserInfo from '@hooks/useUserInfo'
import RatingDistributionWrapper from '@components/RatingDistribution/RatingDistributionWrapper'
import JWVideoPlayer from '@components/Ads/JWVideoPlayer'
import SEOMetaInfo from '@components/SEOMetaInfo'
import isNumericString from '@utils/isNumericString'
import AdNoBid from '@components/Ads/NoBid/AdNoBid'
import OutbrainAd, { StyledOutbrainAd } from '@components/Ads/OutbrainAd'
import StickyHeader, { HEIGHT_TO_TRIGGER_PROF_HEADER } from '@components/StickyHeader/StickyHeader'
import { getCountryName } from './SchoolRatingsPage/SchoolRatings.utils'

const AD_TOP_PROF_PAGE = 120
const AD_TOP_PROF_PAGE_STICKY_HEADER = 236

export const RatingColumn = styled.div`
  display: block;
  > * + * {
    margin-top: var(--row-gap, 16px);
  }
`

/* stylelint-disable */
export const TeacherBlock = styled.div`
  display: flex;
  row-gap: var(--row-gap, 16px);
  column-gap: var(--column-gap, 16px);

  ${StyledTeacher} {
    flex: 1 0 40%;
  }
  ${RatingColumn} {
    min-width: 0;
    flex: 1 1 464px;
  }
  ${Breakpoints.breakpointSmediumAndBelow(css`
    display: block;
    max-width: 546px;
    margin-inline: auto;
    > * + * {
      margin-top: var(--row-gap, 16px);
    }
  `)}
`

const StyledJWVideoPlayerContainer = styled.div`
  max-width: 564px;
`

const StyledBillboardWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  overflow: hidden;
`

const RightRail = styled.div`
  min-height: 300px;
  position: sticky;
`
const TeacherPageWrapper = styled.main`
  --column-gap: ${props => props.theme.pageWrapper.inlineGap}px;
  --row-gap: ${props => props.theme.pageWrapper.blockGap}px;

  --maxWidth: var(
    --max-content-width,
    ${props => props.theme.pageWrapper.inlineGap * 2 + props.theme.pageWrapper.maxWidth}px
  );
  --halfWidth: calc(var(--maxWidth) / 2);
  --inlinePageMargin: calc(50% - var(--halfWidth));

  display: grid;
  column-gap: var(--column-gap, 24px);
  row-gap: var(--row-gap, 24px);
  min-height: calc(
    100vh - ${props => props.theme.header.height + props.theme.leaderboard.height}px
  );
  grid-template-columns:
    [full-start] var(--inlinePageMargin)
    [main-start left-column-start] minmax(0, 1fr)
    [right-rail-start left-column-end] var(--right-rail-width, 300px)
    [right-rail-end main-end] var(--inlinePageMargin)
    [full-end];
  > * {
    grid-column: var(--grid-column, left-column);
  }
  ${StyledBillboardWrapper} {
    --grid-column: full;
  }
  ${StyledTabs}, ${StyledOutbrainAd} {
    overflow: hidden;
    --grid-column: left-column;
  }
  ${RightRail} {
    --grid-column: right-rail;
    grid-row: 2/5;
  }

  ${Breakpoints.breakpointLargeAndBelow(css`
    ${RightRail} {
      grid-row: 2/3;
    }
    ${StyledTabs},
    ${StyledOutbrainAd} {
      --grid-column: main;
    }
  `)}
  ${Breakpoints.breakpointXMediumAndBelow(css`
    ${TeacherBlock} {
      --grid-column: main;
    }
    ${RightRail} {
      --grid-column: full;
      grid-row: auto;
    }
  `)}
  ${Breakpoints.breakpointSmediumAndBelow(
    css`
      --right-rail-width: minmax(0, 1fr);
      > * {
        grid-column: var(--grid-column, main);
      }
    `
  )}
  ${Breakpoints.breakpointTinyAndBelow(
    css`
      ${StyledOutbrainAd} {
        --grid-column: full;
      }
    `
  )}
`

const query = graphql`
  query TeacherRatingsPageQuery($id: ID!) {
    node(id: $id) {
      ... on Teacher {
        id
        legacyId
        firstName
        lastName
        department
        school {
          legacyId
          name
          city
          state
          country
        }
        lockStatus
        ...StickyHeaderContent_teacher
        ...RatingDistributionWrapper_teacher
        ...TeacherInfo_teacher
        ...SimilarProfessors_teacher
        ...TeacherRatingTabs_teacher
      }
    }
  }
`

export function TeacherRatings({ node }) {
  const userInfo = useUserInfo()
  const isLoggedIn = !!userInfo?.Email
  const history = useHistory()
  const historyToastEvent = history?.location?.state?.toast

  const [adTop, setAdTop] = useState(AD_TOP_PROF_PAGE)
  const [adTargeting, setAdTargeting] = useState()

  useEffect(() => {
    if (!node) return

    if (!adTargeting) {
      setAdTargeting({
        school_name: node.school.name,
        school_city: node.school.city,
        school_state: node.school.state,
        school_country: getCountryName(node.school.country)
      })
    }

    const { lockStatus } = node

    if (lockStatus === LOCK_STATUS.HARD) {
      toast(
        <HardlockedTeacherToast teacherName={node.lastName} />,
        TOAST_EVENTS.HARD_LOCKED_TEACHER
      )
    }

    if (lockStatus === LOCK_STATUS.SOFT && !isLoggedIn) {
      toast(
        <SoftlockedTeacherToast teacherName={node.lastName} />,
        TOAST_EVENTS.SOFT_LOCKED_TEACHER
      )
    }

    if (historyToastEvent === TOAST_EVENTS.FLAG_RATING_SUCCESS) {
      toast(<FlagRatingSuccessToast />, TOAST_EVENTS.FLAG_RATING_SUCCESS)
      history.replace({
        ...history.location,
        state: null
      })
    }
  }, [node])

  useEffect(() => {
    const onScroll = () => {
      setAdTop(
        window.scrollY > HEIGHT_TO_TRIGGER_PROF_HEADER
          ? AD_TOP_PROF_PAGE_STICKY_HEADER
          : AD_TOP_PROF_PAGE
      )
    }

    if (window) window.addEventListener('scroll', onScroll)
    return () => {
      if (window) window.removeEventListener('scroll', onScroll)
    }
  }, [])

  if (node === undefined) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  if (node === null || !node.legacyId) {
    return <RedirectWithStatus to={ROUTE.TEACHER_NOT_FOUND} status={301} />
  }

  const getMetaDescription = teacher => {
    if (!teacher.department) {
      return `${teacher.firstName} ${teacher.lastName}  is a professor at ${teacher.school.name} - see what their students are saying about them or leave a rating yourself.`
    }

    return `${teacher.firstName} ${teacher.lastName}  is a professor in the ${teacher.department} department at ${teacher.school.name} - see what their students are saying about them or leave a rating yourself.`
  }

  return (
    <React.Fragment>
      <SEOMetaInfo
        title={`${node.firstName} ${node.lastName} at ${node.school.name} | Rate My Professors`}
        description={getMetaDescription(node)}
      />
      <StickyHeader school={null} teacher={node} large />
      <TeacherPageWrapper>
        <StyledBillboardWrapper>
          <AdNoBid type="billboard" code="ad_lb_top_ratings" align="left" />
        </StyledBillboardWrapper>
        <TeacherBlock>
          <TeacherInfo teacher={node} />
          <RatingColumn>
            <RatingDistributionWrapper teacher={node} />
            <SimilarProfessors teacher={node} />
            <StyledJWVideoPlayerContainer>
              <JWVideoPlayer />
            </StyledJWVideoPlayerContainer>
          </RatingColumn>
        </TeacherBlock>
        <RightRail>
          <AdRail noVerticalPosition adTop={adTop} />
        </RightRail>
        <TeacherRatingTabs teacher={node} />
        <OutbrainAd history={history} />
      </TeacherPageWrapper>
    </React.Fragment>
  )
}

TeacherRatings.propTypes = {
  node: TeacherTypes
}

const getVariables = location => {
  const match = matchPath(location.pathname, { path: ROUTE.SHOW_RATINGS })

  if (!match || !match.params || !match.params.tid || !isNumericString(match.params.tid))
    return { id: '' }
  return { id: btoa(`Teacher-${match.params.tid}`) }
}

export const TeacherRatingsPage = ({ location }) => {
  const variables = getVariables(location)

  if (!variables.id) {
    return <Redirect to={ROUTE.TEACHER_NOT_FOUND} />
  }

  return <QueryWrapper query={query} component={TeacherRatings} variables={variables} />
}

TeacherRatingsPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterTeacherRatingsPage = withRouter(TeacherRatingsPage)
withRouterTeacherRatingsPage.serverQueries = [
  {
    query,
    getVariables
  }
]

export default withRouterTeacherRatingsPage
