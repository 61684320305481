import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { generatePath } from 'react-router-dom'
import LogoBlack from '@assets/images/logos/big_rmp_logo_black.svg'
import HeroBackgroundImage from '@assets/images/rmp-background.jpg'
import { DebouncedSearchInput, SearchInputContainer } from '@components/Search'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import { SearchIconWrapper, StyledSearchWrapper, NEW_SEARCH_ICONS } from '@components/NewSearch'
import { StyledSearchTypeaheadDropdown, StyledScrollContainer } from '@components/SearchTypeahead'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import {
  breakpointSmallPlusAndBelow,
  breakpointLargeAndBelow
} from '@StyledComponents/theme/helpers/breakpoints'
import { setUserSchool, getUserSchool } from '@utils/getAndSetUserSchool'
import useUserInfo from '@hooks/useUserInfo'
import PLACEHOLDER from '@enums/PLACEHOLDER'
import Link, { LINK_TYPE } from '@components/Link'
import ROUTE from '@enums/ROUTE'
import SearchContainer from '@components/Search/SearchContainer'
import HomepageCengageLogo from './HomepageHeroCengageLogo'
import HomepageMcgrawhillLogo from './HomepageHeroMcgrawhillLogo'

const StyledHomepageHero = styled.div`
  background: linear-gradient(0deg, rgba(21, 21, 21, 0.7), rgba(21, 21, 21, 0.7)),
    url(${HeroBackgroundImage});
  background-position-y: center;
  background-size: cover;
  color: ${({ theme }) => theme.color.default};
  padding: 48px 0 48px 0;
  width: 100%;

  ${breakpointSmallPlusAndBelow(
    css`
      padding: 48px 16px;
    `
  )}

  ${StyledSearchTypeaheadDropdown},
  ${StyledScrollContainer} {
    border-radius: 0 0 28px 28px;
    max-width: 545px;
    width: 100%;

    /* Due to proximity to header, z-index is set to be 1 point below header
    rather than the default theme.zIndex.typeahead so that if user scrolls up while
    typeahead is open it will not roll over header or sticky ads*/
    z-index: calc(${props => props.theme.zIndex.header} - 1);
  }

  ${SearchIconWrapper} {
    align-items: center;
    display: flex;
    left: 26;
    position: absolute;

    top: 17px;
  }

  ${SearchInputContainer} {
    width: 100%;
  }

  ${StyledSearchWrapper} {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 545px;
    width: 100%;
  }


  ${StyledSearchTypeaheadDropdown} {
    ${breakpointLargeAndBelow(
      css`
        position: absolute;
        margin: 0;
      `
    )}
  }
`

const Logo = styled.img`
  height: 64px;
  margin-bottom: 15px;
  width: 466px;

  ${breakpointSmallPlusAndBelow(
    css`
      width: 319px;
      height: 43px;
    `
  )}
`

const HeroHeader = styled.div`
  color: white;
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  line-height: 51px;
  margin-bottom: 67px;
  text-align: center;

  ${breakpointSmallPlusAndBelow(
    css`
      font-size: ${({ theme }) => theme.fontSize.teacherCardName};
      margin-bottom: 20px;
    `
  )}
`

const HeroToggle = styled.div`
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 19px;

  ${breakpointSmallPlusAndBelow(
    css`
      font-size: ${({ theme }) => theme.fontSize.default};
    `
  )}
`

const HeroSearch = styled(SearchContainer)`
  ${DebouncedSearchInput} {
    font-size: ${({ theme }) => theme.fontSize.teacherCardName};
    height: 56px;
    margin-bottom: 19px;
    max-width: 545px;
    outline: none;
    padding: 16px 60px;
    width: 100%;

    ${breakpointSmallPlusAndBelow(
      css`
        height: 50px;
        margin-bottom: 16px;
        width: 100%;
      `
    )}
  }
`

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  ${breakpointSmallPlusAndBelow(
    css`
      margin-bottom: 32px;
    `
  )}
`

const HomepageSchoolLink = styled(Link)`
  color: white;
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${breakpointSmallPlusAndBelow(css`
    font-size: ${({ theme }) => theme.fontSize.teacherCardName};
  `)}
`

const getHeroHeader = (isSchoolSearch, schoolName, schoolLegacyId) => {
  if (!isSchoolSearch) {
    return (
      <HeroHeader>
        Find a <b>professor</b>{' '}
        {schoolName && (
          <React.Fragment>
            at{' '}
            <HomepageSchoolLink
              trackingLabel={TRACKING_EVENT.HERO_SAVED_SCHOOL_LINK}
              to={generatePath(ROUTE.NEW_CAMPUS_RATINGS, { sid: schoolLegacyId })}
              type={LINK_TYPE.INTERNAL}
            >
              {schoolName}
            </HomepageSchoolLink>
          </React.Fragment>
        )}
      </HeroHeader>
    )
  }

  return (
    <HeroHeader>
      Enter your <b>school</b> to get started
    </HeroHeader>
  )
}

const buildOnItemSelected = onItemSelectedConfig => newSearchConfig => {
  const { isSchoolSearch, setSchool, setInputValue } = onItemSelectedConfig
  const { event, item } = newSearchConfig

  if (isSchoolSearch) {
    event.preventDefault()
    setSchool(item)
    setInputValue('')
  }
}

export const HERO_MODE = {
  SCHOOLS: {
    toggleText: "I'd like to look up a professor by name",
    getNextMode: () => HERO_MODE.ALL_PROFESSORS
  },
  ALL_PROFESSORS: {
    toggleText: 'I want to find a professor at a school',
    getNextMode: () => HERO_MODE.SCHOOLS
  },
  PROFESSORS_AT_SCHOOL: {
    toggleText: 'I want to find a professor at a different school',
    getNextMode: () => HERO_MODE.SCHOOLS
  }
}

export default function HomepageHero() {
  const userInfo = useUserInfo()
  const userSchool = getUserSchool(userInfo)
  const [school, setSchool] = useState(userSchool)
  const [inputValue, setInputValue] = useState('')
  const [searchMode, setSearchMode] = useState(
    school ? HERO_MODE.PROFESSORS_AT_SCHOOL : HERO_MODE.SCHOOLS
  )
  const isSchoolSearch = searchMode === HERO_MODE.SCHOOLS

  useEffect(() => {
    if (school && searchMode === HERO_MODE.SCHOOLS) {
      setSearchMode(HERO_MODE.PROFESSORS_AT_SCHOOL)
    }
    if (school && userSchool?.id !== school?.id) {
      setUserSchool(school)
    }
  }, [school])

  useEffect(() => {
    if (userSchool && school?.id !== userSchool?.id) setSchool(userSchool)
  }, [userSchool])

  const onItemSelected = buildOnItemSelected({
    setSchool,
    isSchoolSearch,
    inputValue,
    setInputValue
  })

  return (
    <StyledHomepageHero>
      <LogoWrapper>
        <Logo src={LogoBlack} alt="RateMyProfessors Logo" />
        <HomepageCengageLogo />
        <HomepageMcgrawhillLogo />
      </LogoWrapper>
      {getHeroHeader(isSchoolSearch, school?.name, school?.legacyId)}
      <HeroSearch
        searchCategory={isSchoolSearch ? SEARCH_CATEGORY.SCHOOLS : SEARCH_CATEGORY.TEACHERS}
        onItemSelected={onItemSelected}
        placeholder={isSchoolSearch ? PLACEHOLDER.SCHOOL_SEARCH : PLACEHOLDER.PROF_SEARCH}
        schoolID={school?.id}
        iconType={NEW_SEARCH_ICONS.HOME_PAGE}
        inputValue={inputValue}
        setInputValue={setInputValue}
        allowLink
        useErrorText
        useErrorBorder
      />
      <HeroToggle
        onClick={() => {
          if (searchMode === HERO_MODE.PROFESSORS_AT_SCHOOL) {
            setSchool(null)
            setUserSchool(userInfo, null)
          }
          setSearchMode(searchMode.getNextMode())
          setInputValue('')
        }}
      >
        {searchMode.toggleText}
      </HeroToggle>
    </StyledHomepageHero>
  )
}
