/**
 * @flow
 * @relayHash c4f2bb2af70ba03107ee15c8e7c72660
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CompareProfessors_primaryProfessor$ref = any;
type CompareProfessors_secondaryProfessor$ref = any;
export type CompareProfessorsPageQueryVariables = {|
  primaryId: string,
  secondaryId?: ?string,
  skipPrimary: boolean,
  skipSecondary: boolean,
|};
export type CompareProfessorsPageQueryResponse = {|
  +primaryProfessor?: ?({|
    +__typename: "Teacher",
    +$fragmentRefs: CompareProfessors_primaryProfessor$ref,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |}),
  +secondaryProfessor?: ?({|
    +__typename: "Teacher",
    +$fragmentRefs: CompareProfessors_secondaryProfessor$ref,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |}),
|};
export type CompareProfessorsPageQuery = {|
  variables: CompareProfessorsPageQueryVariables,
  response: CompareProfessorsPageQueryResponse,
|};
*/


/*
query CompareProfessorsPageQuery(
  $primaryId: ID!
  $secondaryId: ID
  $skipPrimary: Boolean!
  $skipSecondary: Boolean!
) {
  primaryProfessor: node(id: $primaryId) @skip(if: $skipPrimary) {
    __typename
    ... on Teacher {
      ...CompareProfessors_primaryProfessor
    }
    id
  }
  secondaryProfessor: node(id: $secondaryId) @skip(if: $skipSecondary) {
    __typename
    ... on Teacher {
      ...CompareProfessors_secondaryProfessor
    }
    id
  }
}

fragment CompareProfessors_primaryProfessor on Teacher {
  id
  legacyId
  ...CompareProfessorsColumn_teacher
}

fragment CompareProfessors_secondaryProfessor on Teacher {
  id
  legacyId
  ...CompareProfessorsColumn_teacher
}

fragment CompareProfessorsColumn_teacher on Teacher {
  id
  legacyId
  firstName
  lastName
  school {
    legacyId
    name
    id
  }
  department
  departmentId
  avgRating
  avgDifficulty
  numRatings
  wouldTakeAgainPercentRounded
  mandatoryAttendance {
    yes
    no
    neither
    total
  }
  takenForCredit {
    yes
    no
    neither
    total
  }
  ...NoRatingsArea_teacher
  ...RatingDistributionWrapper_teacher
}

fragment NoRatingsArea_teacher on Teacher {
  lastName
  ...RateTeacherLink_teacher
}

fragment RatingDistributionWrapper_teacher on Teacher {
  ...NoRatingsArea_teacher
  ratingsDistribution {
    total
    ...RatingDistributionChart_ratingsDistribution
  }
}

fragment RatingDistributionChart_ratingsDistribution on ratingsDistribution {
  r1
  r2
  r3
  r4
  r5
}

fragment RateTeacherLink_teacher on Teacher {
  legacyId
  numRatings
  lockStatus
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "primaryId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "secondaryId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipPrimary",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipSecondary",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "primaryId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "secondaryId"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "yes",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "no",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "neither",
    "args": null,
    "storageKey": null
  },
  (v6/*: any*/)
],
v8 = [
  (v2/*: any*/),
  (v4/*: any*/),
  {
    "kind": "InlineFragment",
    "type": "Teacher",
    "selections": [
      (v5/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "school",
        "storageKey": null,
        "args": null,
        "concreteType": "School",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "department",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "departmentId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avgRating",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "avgDifficulty",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "numRatings",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "wouldTakeAgainPercentRounded",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mandatoryAttendance",
        "storageKey": null,
        "args": null,
        "concreteType": "mandatoryAttendance",
        "plural": false,
        "selections": (v7/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "takenForCredit",
        "storageKey": null,
        "args": null,
        "concreteType": "takenForCredit",
        "plural": false,
        "selections": (v7/*: any*/)
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lockStatus",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ratingsDistribution",
        "storageKey": null,
        "args": null,
        "concreteType": "ratingsDistribution",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "r1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "r2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "r3",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "r4",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "r5",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CompareProfessorsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipPrimary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "primaryProfessor",
            "name": "node",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "Teacher",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "CompareProfessors_primaryProfessor",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipSecondary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "secondaryProfessor",
            "name": "node",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "Teacher",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "CompareProfessors_secondaryProfessor",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CompareProfessorsPageQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipPrimary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "primaryProfessor",
            "name": "node",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": (v8/*: any*/)
          }
        ]
      },
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipSecondary",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "secondaryProfessor",
            "name": "node",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "plural": false,
            "selections": (v8/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CompareProfessorsPageQuery",
    "id": null,
    "text": "query CompareProfessorsPageQuery(\n  $primaryId: ID!\n  $secondaryId: ID\n  $skipPrimary: Boolean!\n  $skipSecondary: Boolean!\n) {\n  primaryProfessor: node(id: $primaryId) @skip(if: $skipPrimary) {\n    __typename\n    ... on Teacher {\n      ...CompareProfessors_primaryProfessor\n    }\n    id\n  }\n  secondaryProfessor: node(id: $secondaryId) @skip(if: $skipSecondary) {\n    __typename\n    ... on Teacher {\n      ...CompareProfessors_secondaryProfessor\n    }\n    id\n  }\n}\n\nfragment CompareProfessors_primaryProfessor on Teacher {\n  id\n  legacyId\n  ...CompareProfessorsColumn_teacher\n}\n\nfragment CompareProfessors_secondaryProfessor on Teacher {\n  id\n  legacyId\n  ...CompareProfessorsColumn_teacher\n}\n\nfragment CompareProfessorsColumn_teacher on Teacher {\n  id\n  legacyId\n  firstName\n  lastName\n  school {\n    legacyId\n    name\n    id\n  }\n  department\n  departmentId\n  avgRating\n  avgDifficulty\n  numRatings\n  wouldTakeAgainPercentRounded\n  mandatoryAttendance {\n    yes\n    no\n    neither\n    total\n  }\n  takenForCredit {\n    yes\n    no\n    neither\n    total\n  }\n  ...NoRatingsArea_teacher\n  ...RatingDistributionWrapper_teacher\n}\n\nfragment NoRatingsArea_teacher on Teacher {\n  lastName\n  ...RateTeacherLink_teacher\n}\n\nfragment RatingDistributionWrapper_teacher on Teacher {\n  ...NoRatingsArea_teacher\n  ratingsDistribution {\n    total\n    ...RatingDistributionChart_ratingsDistribution\n  }\n}\n\nfragment RatingDistributionChart_ratingsDistribution on ratingsDistribution {\n  r1\n  r2\n  r3\n  r4\n  r5\n}\n\nfragment RateTeacherLink_teacher on Teacher {\n  legacyId\n  numRatings\n  lockStatus\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2897d8e3c37ef4b4221e1b830ee37e70';
module.exports = node;
