/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Route } from 'react-router-dom'
import ROUTE from '@enums/ROUTE'
import TeacherRatingsPage from '@pages/TeacherRatingsPage'
import SchoolRatingsPage from '@pages/SchoolRatingsPage/SchoolRatingsPage'
import UserPagesIndex from '@pages/UserPagesIndex'
import GUISandboxPage from '@pages/GUISandboxPage'
import PageNotFound from '@pages/PageNotFound'
import TeacherNotFound from '@pages/TeacherNotFound'
import CCPAPage from '@pages/CCPAPage'
import LegalHashRouting from '@pages/LegalHashRouting'
import ModalLandingPage from '@pages/ModalLandingPage'
import { LegalPrivacy, LegalTerms, LegalCopyright, LegalGuidelines } from '@pages/LegalPage'
import TeacherSearchResultsPage from '@pages/Search/TeacherSearchResultsPage'
import SchoolSearchResultsPage from '@pages/Search/SchoolSearchResultsPage'
import Homepage from '@pages/Homepage/Homepage'
import AddRatingTeacherPage from '@pages/AddRating/RateTeacher/AddRatingTeacherPage'
import EditRatingTeacherPage from '@pages/AddRating/RateTeacher/EditRatingTeacherPage'
import AddRatingSchoolPage from '@pages/AddRating/RateSchool/AddRatingSchoolPage'
import EditRatingSchoolPage from '@pages/AddRating/RateSchool/EditRatingSchoolPage'
import FlagTeacherRatingPage from '@pages/FlagTeacherRating/FlagTeacherRatingPage'
import FlagCampusRatingPage from '@pages/FlagCampusRating/FlagCampusRatingPage'
import AddProfessorPage from '@pages/AddProfessor/AddProfessorPage'
import AddCampusPage from '@pages/AddCampus/AddCampusPage'
import SuccessPage from '@pages/Success/SuccessPage'
import CompareSchoolsPage from '@pages/CompareSchoolsPage/CompareSchoolsPage'
import CompareProfessorsPage from '@pages/CompareProfessorsPage/CompareProfessorsPage'
import RatingPage from '@pages/RatingPage/RatingPage'
import RatingNotFound from '@pages/RatingNotFound'
import SchoolNotFound from '@pages/SchoolNotFound'

export const routeConfigs = [
  {
    path: ROUTE.HOME,
    component: Homepage,
    exact: true,
    usesLightHeader: true,
    adUnitPath: 'home',
    allowsEmailConfirmationPrompt: true,
    /**
     * The homepage uses a different full width leaderboard, so this is turned off
     */
    showLeaderboard: false
  },
  {
    path: ROUTE.SHOW_RATINGS,
    component: TeacherRatingsPage,
    exact: true,
    adUnitPath: 'prof_profile',
    allowsEmailConfirmationPrompt: true,
    showLeaderboard: false // we apply a separate leaderboard ad (not the default) for ad targeting
  },
  {
    path: ROUTE.NEW_CAMPUS_RATINGS,
    component: SchoolRatingsPage,
    exact: true,
    adUnitPath: 'school_profile',
    allowsEmailConfirmationPrompt: true,
    stickyHeader: true,
    showLeaderboard: false
  },
  {
    path: ROUTE.USER_ACCOUNT,
    component: UserPagesIndex,
    allowsEmailConfirmationPrompt: true
  },
  {
    path: ROUTE.UI_SANDBOX,
    component: GUISandboxPage,
    exact: true
  },
  { path: ROUTE.CCPA, component: CCPAPage, exact: true },
  { path: ROUTE.TEACHER_NOT_FOUND, component: TeacherNotFound },
  { path: ROUTE.CAMPUS_NOT_FOUND, component: SchoolNotFound },
  { path: ROUTE.RATING_NOT_FOUND, component: RatingNotFound },
  { path: ROUTE.LEGAL_OLD_RMP, component: LegalHashRouting },
  { path: ROUTE.LEGAL_OLD_RMP_UTILITY, component: LegalHashRouting },
  { path: ROUTE.LEGAL_OLD_RMP_AD_CHOICES, component: LegalPrivacy },
  { path: ROUTE.LEGAL_PRIVACY, component: LegalPrivacy },
  { path: ROUTE.LEGAL_TERMS, component: LegalTerms },
  { path: ROUTE.LEGAL_COPYRIGHT, component: LegalCopyright },
  { path: ROUTE.LEGAL_SITE_GUIDELINES, component: LegalGuidelines },
  {
    path: ROUTE.TEACHER_SEARCH,
    component: TeacherSearchResultsPage,
    adUnitPath: 'search',
    allowsEmailConfirmationPrompt: true,
    showLeaderboard: false // sometimes we have no results so we don't want to show the default leaderboard
  },
  {
    path: ROUTE.TEACHER_SEARCH_ALL,
    component: TeacherSearchResultsPage,
    adUnitPath: 'search',
    exact: true,
    allowsEmailConfirmationPrompt: true,
    showLeaderboard: false // sometimes we have no results so we don't want to show the default leaderboard
  },
  {
    path: ROUTE.OLD_TEACHER_SEARCH,
    component: TeacherSearchResultsPage,
    adUnitPath: 'search'
  },
  {
    path: ROUTE.SCHOOL_SEARCH,
    component: SchoolSearchResultsPage,
    adUnitPath: 'search',
    allowsEmailConfirmationPrompt: true,
    showLeaderboard: false // sometimes we have no results so we don't want to show the default leaderboard
  },
  {
    path: ROUTE.NEW_ADD_RATING_TEACHER,
    component: AddRatingTeacherPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    stickyHeader: true
  },
  {
    path: ROUTE.NEW_ADD_RATING_SCHOOL,
    component: AddRatingSchoolPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    stickyHeader: true
  },
  {
    path: ROUTE.NEW_EDIT_RATING_TEACHER,
    component: EditRatingTeacherPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.NEW_EDIT_RATING_SCHOOL,
    component: EditRatingSchoolPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.ADD_PROFESSOR_RATING_SUCCESS,
    component: TeacherSearchResultsPage,
    adUnitPath: 'search',
    allowsEmailConfirmationPrompt: true
  },
  {
    path: ROUTE.ADD_CAMPUS_RATING_SUCCESS,
    component: TeacherSearchResultsPage,
    adUnitPath: 'search',
    allowsEmailConfirmationPrompt: true
  },
  {
    path: ROUTE.ADD_PROFESSOR_SUCCESS,
    component: SuccessPage,
    adUnitPath: 'search',
    allowsEmailConfirmationPrompt: true
  },
  {
    path: ROUTE.ADD_CAMPUS_SUCCESS,
    component: SuccessPage,
    adUnitPath: 'search',
    allowsEmailConfirmationPrompt: true
  },
  { path: ROUTE.LOGOUT, component: ModalLandingPage },
  {
    path: ROUTE.NEW_LOGOUT,
    component: Homepage,
    exact: true,
    usesLightHeader: true,
    adUnitPath: 'home',
    allowsEmailConfirmationPrompt: false
  },
  {
    path: ROUTE.NEW_FLAG_PROF_RATING,
    component: FlagTeacherRatingPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.NEW_ADD_PROFESSOR,
    component: AddProfessorPage,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    showLeaderboard: true
  },
  {
    path: ROUTE.NEW_ADD_CAMPUS,
    component: AddCampusPage,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    showLeaderboard: true
  },
  {
    path: ROUTE.NEW_FLAG_CAMPUS_RATING,
    component: FlagCampusRatingPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.COMPARE_SCHOOLS,
    component: CompareSchoolsPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.COMPARE_PROFESSORS,
    component: CompareProfessorsPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.COMPARE_BLANK,
    component: CompareSchoolsPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.RATING,
    component: RatingPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  { path: ROUTE.NEW_LOGIN, component: ModalLandingPage },
  { path: ROUTE.NEW_SIGN_UP, component: ModalLandingPage },
  { path: ROUTE.RESET_PASSWORD, component: ModalLandingPage },
  { path: ROUTE.FORGOT_PASSWORD, component: ModalLandingPage },
  { path: ROUTE.CONFIRM_EMAIL, component: ModalLandingPage },
  { path: ROUTE.PROFESSOR_SIGN_UP, component: ModalLandingPage },
  { path: ROUTE.STUDENT_SIGN_UP, component: ModalLandingPage },
  /**
   * The below routes are old routes but still need links to proper pages/components
   * for SSR to work properly. See Bug Story below
   *  - https://app.shortcut.com/cheddar/story/25878/update-ssr-to-include-proper-components-for-redirect-routes
   */
  {
    path: ROUTE.OLD_LOCKHART_PROFESSOR_RATINGS,
    component: TeacherRatingsPage,
    exact: true,
    adUnitPath: 'prof_profile',
    allowsEmailConfirmationPrompt: true
  },
  {
    path: ROUTE.OLD_RMP_CAMPUS_RATINGS,
    component: SchoolRatingsPage,
    exact: true,
    adUnitPath: 'school_profile',
    allowsEmailConfirmationPrompt: true,
    stickyHeader: true
  },
  {
    path: ROUTE.OLD_LOCKHART_CAMPUS_RATINGS,
    component: SchoolRatingsPage,
    exact: true,
    adUnitPath: 'school_profile',
    allowsEmailConfirmationPrompt: true,
    stickyHeader: true
  },
  {
    path: ROUTE.OLD_RMP_ADD_RATING_TEACHER,
    component: AddRatingTeacherPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    stickyHeader: true
  },
  {
    path: ROUTE.OLD_LOCKHART_ADD_RATING_TEACHER,
    component: AddRatingTeacherPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    stickyHeader: true
  },
  {
    path: ROUTE.OLD_LOCKHART_ADD_RATING_SCHOOL,
    component: AddRatingSchoolPage,
    exact: true,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings',
    stickyHeader: true
  },
  {
    path: ROUTE.OLD_RMP_FLAG_CAMPUS_RATING,
    component: FlagCampusRatingPage,
    allowsEmailConfirmationPrompt: false,
    adUnitPath: 'prof_ratings'
  },
  {
    path: ROUTE.OLD_RMP_FLAG_PROFESSOR_RATING,
    component: AddProfessorPage,
    allowsEmailConfirmationPrompt: true,
    adUnitPath: 'prof_ratings'
  },
  { component: PageNotFound }
]

export default routeConfigs

export function generateRouteList() {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return routeConfigs.map((routeConfig, i) => <Route key={i} {...routeConfig} />)
}
