/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

const MeterList = styled.ul`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(5, 1fr);
  column-gap: var(--column-gap, 16px);
  row-gap: var(--row-gap, 16px);
`
const Title = styled.h6`
  text-align: left;
  font-weight: 900;
  font-size: 1em;
  line-height: 1.25;
`

const MeterItem = styled.li`
  display: grid;
  align-items: center;
  text-align: right;
  grid-column: 1/-1;
  grid-template-columns: subgrid;
  b {
    text-align: left;
  }
`
const MeterBox = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.gray5};
`
/* stylelint-disable */
const StyledMeter = styled.meter.attrs(({ meterHeight, delay }) => ({
  style: {
    '--transition-delay': `${delay}s`,
    '--meter-height': meterHeight ?? 'inherit'
  }
}))`
  width: 100%;
  height: var(--meter-height, 36px);
  background: none;
  background-color: ${props => props.theme.color.gray5};
  transform-origin: 0 0;
  transform: scaleX(0);
  transition: transform 0.5s;
  transition-delay: var(--transition-delay, 0);
  .animate & {
    transform: scaleX(1);
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-meter-bar {
    height: var(--meter-height, 36px);
    border-radius: 0;
    display: inline-block;
    background: none;
    background-color: ${props => props.theme.color.gray5};
  }
  &::-webkit-meter-optimum-value {
    background: none;
    background-color: ${props => props.theme.color.mainBlue};
  }
  :-moz-meter-optimum::-moz-meter-bar {
    background: none;
    background-color: ${props => props.theme.color.mainBlue};
  }
`
const Label = styled.label`
  font-size: 0.85em;
`
const LabelText = styled.span`
  display: var(--label-display, inline);
`
const LabelValue = styled.span`
  font-weight: bold;
`
const Wrapper = styled.aside`
  > * + * {
    margin-top: var(--row-gap, 16px);
  }
`
export const RatingDistributionChart = ({ ratingsDistribution, compareProfessors }) => {
  const [loaded, setLoaded] = useState(false)
  const listRef = useRef(null)
  useEffect(() => {
    if (!listRef.current) return () => undefined
    let timer
    const observer = new IntersectionObserver(
      entries => {
        entries
          .filter(entry => entry.target === listRef.current && entry.isIntersecting)
          .forEach(() => {
            timer = setTimeout(() => setLoaded(true), 250)
            observer.unobserve(listRef.current)
          })
      },
      {
        threshold: 0.1
      }
    )
    observer.observe(listRef.current)
    return () => {
      clearTimeout(timer)
      observer.unobserve(listRef.current)
    }
  }, [listRef])

  const chartData = Object.values(ratingsDistribution).reverse()
  const maxNum = Math.max(...chartData)
  const labels = ['Awesome', 'Great', 'Good', 'OK', 'Awful']
  return (
    <Wrapper>
      {compareProfessors ? null : <Title>Rating Distribution</Title>}
      <MeterList ref={listRef} compareProfessors className={loaded ? 'animate' : ''}>
        {chartData.map((score, index) => {
          const label = labels[index]
          if (!label) return null
          const id = `rating-${label.split(' ')[0].toLowerCase()}`
          return (
            <MeterItem key={index}>
              <Label htmlFor={id}>
                <LabelText>{label}</LabelText> <LabelValue>{chartData.length - index}</LabelValue>
              </Label>
              <MeterBox>
                <StyledMeter
                  delay={index * 0.05}
                  id={id}
                  value={score}
                  min={0}
                  max={maxNum}
                  low={0}
                  high={maxNum}
                >
                  {score}
                </StyledMeter>
              </MeterBox>
              <b>{score}</b>
            </MeterItem>
          )
        })}
      </MeterList>
    </Wrapper>
  )
}

RatingDistributionChart.propTypes = {
  ratingsDistribution: PropTypes.shape({
    r1: PropTypes.number,
    r2: PropTypes.number,
    r3: PropTypes.number,
    r4: PropTypes.number,
    r5: PropTypes.number,
    total: PropTypes.number
  }),
  compareProfessors: PropTypes.bool
}

export default createFragmentContainer(RatingDistributionChart, {
  ratingsDistribution: graphql`
    fragment RatingDistributionChart_ratingsDistribution on ratingsDistribution {
      r1
      r2
      r3
      r4
      r5
    }
  `
})
