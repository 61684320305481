/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import * as Breakpoints from '@components/StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import SchoolTypes from '@types/School.types'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import StickyHeaderContent from './StickyHeaderContent'

const SCHOOL_HEADER_HEIGHT = 238
export const HEIGHT_TO_TRIGGER_PROF_HEADER = 624

export const StyledStickyHeader = styled.div`
  background: white;
  box-shadow: 0px 4px 4px rgba(126, 126, 126, 0.25);
  display: flex;
  align-items: center;
  min-width: 100%;
  position: ${({ visible, position }) => (visible ? position : 'absolute')};
  top: 62px;
  z-index: ${({ theme }) => theme.zIndex.stickyHeader};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(-20px)')};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  transition: opacity 0.5s ease, transform 0.5s ease;
  max-height: 232px;

  padding: 8px 24px 16px;

  ${Breakpoints.breakpointXLargeAndBelow(css`
    ${props => props.school && `max-height: 232px;`}
  `)}

  ${Breakpoints.breakpointSmediumAndBelow(css`
    ${props => props.school && `max-height: 264px;`}
    min-height: ${({ rateTeacher, rateSchool }) => (rateTeacher || rateSchool ? '78px' : '172px')};
  `)}
`

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => Breakpoints.getPageContainerMaxWidth(theme)};

  ${Breakpoints.breakpointSmediumAndBelow(css`
    min-width: 100%;
  `)}
`

const StickyHeader = ({
  teacher,
  large,
  school,
  searchPage,
  rateTeacher,
  rateSchool,
  showButtons = true,
  query,
  itemType,
  ...props
}) => {
  const [scrolled, setScrolled] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const headerHeights = school ? SCHOOL_HEADER_HEIGHT : HEIGHT_TO_TRIGGER_PROF_HEADER

    const onScroll = () => {
      setVisible(window.scrollY > headerHeights)
      setScrolled(window.scrollY > headerHeights)
    }
    if (window) window.addEventListener('scroll', onScroll)
    return () => {
      if (window) window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <StyledStickyHeader
      scrolled={scrolled}
      visible={school || rateTeacher || rateSchool || searchPage || visible}
      position={school || rateTeacher || rateSchool || searchPage ? 'sticky' : 'fixed'}
      school={school}
      rateTeacher={rateTeacher}
      rateSchool={rateSchool}
    >
      <StyledInnerContainer>
        <StickyHeaderContent
          teacher={teacher}
          large={large}
          school={school}
          searchPage={searchPage}
          showButtons={showButtons}
          query={query}
          itemType={itemType}
          {...props}
        />
      </StyledInnerContainer>
    </StyledStickyHeader>
  )
}

StickyHeader.propTypes = {
  teacher: TeacherTypes,
  large: PropTypes.bool,
  school: SchoolTypes,
  searchPage: PropTypes.bool,
  rateTeacher: PropTypes.bool,
  rateSchool: PropTypes.bool,
  showButtons: PropTypes.bool,
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    fallback: PropTypes.bool,
    departmentID: PropTypes.string
  }),
  itemType: PropTypes.oneOf(Object.values(SEARCH_ITEM_TYPES))
}

export default StickyHeader
