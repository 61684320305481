import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import RenderForBreakpoints from '@StyledComponents/RenderForBreakpoints'
import AdNoBid from '@components/Ads/NoBid/AdNoBid'
import * as Breakpoints from '@components/StyledComponents/theme/helpers/breakpoints'

const DEFAULT_AD_TOP = 220
const MIN_HEIGHT_FOR_SECOND_AD_UNIT = 900

export const StyledAdRail = styled.div.attrs(({ skipMargin }) => ({
  style: {
    '--positionLeft': skipMargin ? '0' : 'inherit',
    '--position': skipMargin ? 'sticky' : 'inherit'
  }
}))`
  display: block;
  flex-grow: 0;
  position: var(--position, fixed);
  max-height: 600px;
  top: ${props => props.adTop}px;
  z-index: ${props => props.theme.zIndex.adRail};
  ${Breakpoints.applyBreakpointMin(
    'large',
    css`
      margin-left: var(
        --positionLeft,
        calc(
          ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px +
            ${props => props.theme.adRail.margin}px
        )
      );
    `
  )}
  ${Breakpoints.breakpointLargeAndBelow(
    css`
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
    `
  )}
  > * + * {
    margin-top: 5px;
  }
`

const Divider = styled.div`
  height: 16px;
`

export default function AdRail({
  adTargetingSchools,
  noVerticalPosition = false,
  adTop = DEFAULT_AD_TOP
}) {
  const [showSecondAd, setShowSecondAd] = useState(false)

  /**
   * This observes changes the window size which we use to show / hide the second ad unit
   */
  useEffect(() => {
    const checkHeightAndSetAd = () => {
      const pageHeight = document.documentElement.clientHeight
      setShowSecondAd(pageHeight > MIN_HEIGHT_FOR_SECOND_AD_UNIT)
    }

    checkHeightAndSetAd()

    window.addEventListener('resize', checkHeightAndSetAd)

    return () => {
      window.removeEventListener('resize', checkHeightAndSetAd)
    }
  }, [showSecondAd])

  /* eslint-disable */
  return (
    <StyledAdRail skipMargin={noVerticalPosition} adTop={adTop}>
      <AdNoBid type="mrec" code="ad_rec_01_profile" targeting={adTargetingSchools} />
      {showSecondAd && (
        <RenderForBreakpoints min="large">
          <Divider />
          <AdNoBid type="mrec" code="ad_rec_01_profile" targeting={adTargetingSchools} />
        </RenderForBreakpoints>
      )}
    </StyledAdRail>
  )
}

AdRail.propTypes = {
  // This needs to be object b/c targeting varies by page
  // eslint-disable-next-line react/forbid-prop-types
  adTargetingSchools: PropTypes.object,
  adTop: PropTypes.number,
  noVerticalPosition: PropTypes.number
}
