import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import FeedbackItem from './FeedbackItem'

export const StyledTeacherFeedback = styled.div`
  display: flex;
  position: relative;
  gap: 25px;

  & > :nth-child(2):before {
    content: '';
    position: absolute;
    inset: 0 auto;
    width: 1px;
    transform: translateX(-12px) scaleY(1.25);
    background: ${props => props.theme.color.default};
  }
`

export function TeacherFeedback({ teacher }) {
  if (!teacher) return null

  const wouldTakeAgain =
    teacher.wouldTakeAgainPercent < 0 ? 'N/A' : `${Math.ceil(teacher.wouldTakeAgainPercent)}%`

  return (
    <StyledTeacherFeedback>
      <FeedbackItem
        number={wouldTakeAgain}
        numRatings={teacher.numRatings}
        label="Would take again"
      />
      <FeedbackItem
        number={teacher.avgDifficulty}
        numRatings={teacher.numRatings}
        label="Level of Difficulty"
      />
    </StyledTeacherFeedback>
  )
}

TeacherFeedback.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(TeacherFeedback, {
  teacher: graphql`
    fragment TeacherFeedback_teacher on Teacher {
      numRatings
      avgDifficulty
      wouldTakeAgainPercent
    }
  `
})
