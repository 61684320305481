import React from 'react'
import styled from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import parseAndSanitize from '@utils/parseAndSanitize'
import Tag from '@StyledComponents/Tag'
import TeacherTypes from '@types/Teacher.types'
import { poppinsBoldMedium } from '@StyledComponents/theme/typesets'

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 464px;
  column-gap: 16px;
  row-gap: 8px;
`

export const TagsHeader = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  ${poppinsBoldMedium};
`

/* stylelint-disable */
export const TagsWrapper = styled.div`
  > * + * {
    margin-top: 16px;
  }
  ${Tag} {
    margin: 0;
  }
`

export function TeacherTags({ teacher }) {
  const { teacherRatingTags, lastName } = teacher
  if (!teacherRatingTags || !teacherRatingTags.length) return null

  let displayTags = [].concat(teacherRatingTags)
  displayTags.sort((a, b) => b.tagCount - a.tagCount)
  displayTags = displayTags.slice(0, 5)

  return (
    <TagsWrapper>
      <TagsHeader>Professor {parseAndSanitize(lastName)}&apos;s Top Tags</TagsHeader>
      <TagsContainer>
        {displayTags.map(tag => (
          <Tag key={`${tag.legacyId.toString()}`}>{tag.tagName}</Tag>
        ))}
      </TagsContainer>
    </TagsWrapper>
  )
}

TeacherTags.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(TeacherTags, {
  teacher: graphql`
    fragment TeacherTags_teacher on Teacher {
      lastName
      teacherRatingTags {
        legacyId
        tagCount
        tagName
      }
    }
  `
})
