import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpointSmediumAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import ToastContainer from '@components/Toast/ToastContainer'
import UserMenu, {
  MyAccountButton,
  LoginLink,
  SignUpLink,
  UserMenuWrapper
} from '@components/UserMenu'
import MobileMenu, { MobileMenuWrapper } from '@components/MobileMenu'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import AdNoBid from '@components/Ads/NoBid/AdNoBid'

const noMarginBelowSmedium = css`
  ${breakpointSmediumAndBelow(
    css`
      margin: 0;
    `
  )}
`

const LoginButtonStyles = css`
  color: black;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: 8px 12px;

  ${noMarginBelowSmedium}
  &:hover {
    background-color: unset;
    text-decoration: underline;
  }
`
const SignupButtonStyles = css`
  background-color: black;
  border-color: black;
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding: 8px 24px;

  ${noMarginBelowSmedium}
  &:hover {
    background-color: ${({ theme }) => theme.color.gray10};
  }
`
const AccountButtonStyles = css`
  background-color: black;
  border: 2px solid black;
  color: white;
  font-size: ${({ theme }) => theme.fontSize.medium};

  padding: 8px 24px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray10};
    text-decoration: none;
  }
`

const UserItems = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.medium};
  z-index: ${props => props.theme.zIndex.search};

  ${breakpointSmediumAndBelow(css`
    ${MobileMenuWrapper} {
      display: block;
    }

    ${UserMenuWrapper},
    ${MyAccountButton} {
      display: none;
    }
  `)}

  ${LoginLink} {
    ${LoginButtonStyles}
  }

  ${MyAccountButton} {
    ${AccountButtonStyles}
  }

  ${SignUpLink} {
    ${SignupButtonStyles}
  }
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 152px;
  padding: 16px;

  ${breakpointSmediumAndBelow(css`
    flex-direction: column;
  `)}
`

const HeaderCenterContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderRightContent = styled.div`
  align-self: flex-end;

  ${breakpointSmediumAndBelow(css`
    order: -1;
    align-self: flex-end;
  `)}
`

const Spacer = styled.div`
  width: 15%;
  visibility: hidden;
`

export default function HomepageHeader() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (isMobileMenuOpen) {
      disableBodyScroll()
      return
    }

    enableBodyScroll()
  }, [isMobileMenuOpen])

  return (
    <StyledHeaderContainer>
      <ToastContainer limit={1} />
      <Spacer />
      <HeaderRightContent>
        <UserItems>
          <UserMenu />
          <MobileMenu
            isLightButton={false}
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </UserItems>
      </HeaderRightContent>
      <HeaderCenterContent>
        <AdNoBid type="billboard" code="ad_lb_top_home" />
      </HeaderCenterContent>
    </StyledHeaderContainer>
  )
}
