import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import TeacherTypes from '@types/Teacher.types'
import NoRatingsArea from '@StyledComponents/NoRatingsArea'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import RatingDistributionChart from './RatingDistributionChart'

export const StyledRatingDistributionWrapper = styled.div.attrs(({ compareProfessors }) => ({
  style: {
    '--row-gap': compareProfessors ? '8px' : '22px',
    '--column-gap': compareProfessors ? '8px' : '16px',
    '--label-display': compareProfessors ? 'none' : 'inherit',
    fontSize: '18px'
  }
}))`
  background-color: ${props => !props.compareProfessors && props.theme.color.gray7};
  padding: var(--row-gap, 16px) var(--column-gap, 16px);
  font-size: 16px;
  overflow: hidden;
  height: auto;
  width: 100%;
  > * + * {
    margin-top: 8px;
  }
`

export const RatingDistributionWrapper = ({ teacher, compareProfessors }) => {
  return (
    <StyledRatingDistributionWrapper compareProfessors={compareProfessors}>
      {teacher.ratingsDistribution && teacher.ratingsDistribution.total ? (
        <RatingDistributionChart
          ratingsDistribution={teacher.ratingsDistribution}
          compareProfessors={compareProfessors}
        />
      ) : (
        <NoRatingsArea
          teacher={teacher}
          trackingLabel={TRACKING_EVENT.PROF_RATE_BUTTON_CENTER}
          gray
        />
      )}
    </StyledRatingDistributionWrapper>
  )
}

RatingDistributionWrapper.propTypes = {
  teacher: TeacherTypes,
  compareProfessors: PropTypes.bool
}

export default createFragmentContainer(RatingDistributionWrapper, {
  teacher: graphql`
    fragment RatingDistributionWrapper_teacher on Teacher {
      ...NoRatingsArea_teacher
      ratingsDistribution {
        total
        ...RatingDistributionChart_ratingsDistribution
      }
    }
  `
})
