import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'
import { breakpointLargeAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import TeacherBookmark, { StyledTeacherBookmark } from '@StyledComponents/TeacherBookmark'
import TRACKING_EVENT from '@enums/TRACKING_EVENT'
import parseAndSanitize from '@utils/parseAndSanitize'
import { poppinsBoldest } from '@StyledComponents/theme/typesets'
import TeacherDepartment from './TeacherDepartment'

export const Name = styled.div`
  font-size: 40px;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  ${poppinsBoldest};
  ${StyledTeacherBookmark} {
    margin-left: 8px;
    img {
      height: 32px;
      width: 32px;
    }
  }
`

export const Title = styled.div`
  max-width: 100%;
  .span,
  a {
    font-weight: ${props => props.theme.fontWeight.bold};
  }
`

const NameWrapper = styled.h1`
  /* margin-bottom: 0.2em !important; */
  white-space: normal;
  && {
    margin-block-end: 0;
  }
`
const Wrapper = styled.div`
  > * + * {
    margin-top: 18px;
    ${breakpointLargeAndBelow(
      css`
        position: relative;
      `
    )}
  }
`

export function NameTitle({ teacher }) {
  if (!teacher) return null

  return (
    <Wrapper>
      <Name>
        <NameWrapper>
          {parseAndSanitize(teacher.firstName)} {parseAndSanitize(teacher.lastName)}
          <TeacherBookmark
            teacher={teacher}
            saveEvent={TRACKING_EVENT.PROFILE_PAGE_SAVED_PROF_ADD}
            unsaveEvent={TRACKING_EVENT.PROFILE_PAGE_SAVED_PROF_REMOVE}
          />
        </NameWrapper>
      </Name>
      <Title>
        <TeacherDepartment teacher={teacher} />
      </Title>
    </Wrapper>
  )
}

NameTitle.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(NameTitle, {
  teacher: graphql`
    fragment NameTitle_teacher on Teacher {
      id
      firstName
      lastName
      department
      school {
        legacyId
        name
      }
      ...TeacherDepartment_teacher
      ...TeacherBookmark_teacher
    }
  `
})
