import React, { useState } from 'react'
import { graphql } from 'react-relay'
import { matchPath, withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import ROUTE from '@enums/ROUTE'
import QueryWrapper from '@componentUtils/QueryWrapper'
import PropTypes from 'prop-types'
import SEARCH_ITEM_TYPES from '@enums/SEARCHITEMTYPES'
import TeacherTypes from '@types/Teacher.types'
import isNumericString from '@utils/isNumericString'
import SchoolTypes from '@types/School.types'
import sanitizeQuery from '@utils/sanitizeQuery'
import isSSR from '@utils/isSSR'
import TeacherSearchPagination from './TeacherSearchPagination'

const graphqlQuery = graphql`
  query TeacherSearchResultsPageQuery(
    $query: TeacherSearchQuery!
    $schoolID: ID
    $includeSchoolFilter: Boolean!
  ) {
    search: newSearch {
      ...TeacherSearchPagination_search @arguments(count: 8, cursor: "", query: $query)
    }
    school: node(id: $schoolID) @include(if: $includeSchoolFilter) {
      ... on School {
        # Explicitly calling name here for SEO meta info which renders before sticky header is called
        name
        ...StickyHeaderContent_school
      }
    }
  }
`

export const TeacherSearchResultsPage = props => {
  const { search, query, school, filter } = props
  return (
    <TeacherSearchPagination
      search={search || null}
      query={query}
      itemType={SEARCH_ITEM_TYPES.TEACHERS}
      school={school}
      filter={filter}
      {...props}
    />
  )
}
const getVariables = (location, filter) => {
  const params = new URLSearchParams(location.search)
  const departmentID =
    /* eslint-disable-next-line no-nested-ternary, no-use-before-define */
    filter && filter.id ? filter.id : isSSR() ? getPrefilterId(params) ?? undefined : undefined

  /* eslint-disable-next-line no-use-before-define */
  const schoolID = getSchoolId(location, params)
  const includeSchoolFilter = !!schoolID

  let queryText = params.get('q') || ''
  if (queryText === '*') queryText = ''
  const queryTextSanitized = sanitizeQuery(queryText)

  const queryObject = {
    query: {
      text: queryTextSanitized,
      schoolID,
      fallback: true,
      departmentID
    },
    schoolID,
    includeSchoolFilter
  }
  return queryObject
}

function getSchoolId(location, params) {
  const match = matchPath(location.pathname, {
    path: [
      ROUTE.TEACHER_SEARCH,
      ROUTE.ADD_CAMPUS_RATING_SUCCESS,
      ROUTE.ADD_PROFESSOR_RATING_SUCCESS
    ]
  })
  let schoolID = match?.params?.sid || params.get('sid') || ''

  if (schoolID && isNumericString(schoolID) && schoolID !== '0') {
    schoolID = btoa(`School-${schoolID}`)
    /**
     * If the schoolID is valid then we will include the school node on the response
     */
  } else if (schoolID.indexOf('U2Nob29s') === 0) {
    /**
     * A valid base64-encoded school id starts with "U2Nob29s"
     * so include the school filter
     */
  } else {
    // if it isnt a numer and it isnt valid base64, bail
    schoolID = ''
  }
  return schoolID
}

function getPrefilterId(params) {
  const departmentID = params.get('did') || ''
  return isNumericString(departmentID) ? btoa(`Department-${departmentID}`) : null
}

export const TeacherSearchResultsPageQueryWrapper = ({ location }) => {
  const [filter, setFilter] = useState({
    id: getPrefilterId(new URLSearchParams(location.search))
  })
  const variables = getVariables(location, filter)

  const queryVariables = variables
  const { query } = variables

  const successPageData =
    (matchPath(location.pathname, ROUTE.ADD_PROFESSOR_RATING_SUCCESS) ||
      matchPath(location.pathname, ROUTE.ADD_CAMPUS_RATING_SUCCESS)) &&
    location.state

  return (
    <div>
      <QueryWrapper
        query={graphqlQuery}
        variables={queryVariables}
        component={props => (
          <TeacherSearchResultsPage
            {...props}
            query={query}
            setFilter={setFilter}
            filter={filter}
            successPageData={successPageData}
          />
        )}
      />
    </div>
  )
}

TeacherSearchResultsPage.propTypes = {
  search: PropTypes.shape({
    teachers: PropTypes.shape({
      edges: PropTypes.arrayOf({ cursor: PropTypes.string, node: TeacherTypes })
    })
  }),
  school: SchoolTypes,
  query: PropTypes.shape({
    text: PropTypes.string,
    schoolID: PropTypes.string,
    departmentID: PropTypes.string
  }),
  filter: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  })
}

TeacherSearchResultsPageQueryWrapper.propTypes = {
  location: ReactRouterPropTypes.location
}
const withRouterTeacherSearchResultsPageQueryWrapper = withRouter(
  TeacherSearchResultsPageQueryWrapper
)
withRouterTeacherSearchResultsPageQueryWrapper.serverQueries = [
  {
    query: graphqlQuery,
    getVariables
  }
]
export default withRouterTeacherSearchResultsPageQueryWrapper
